import { FC } from 'react';

import { wrap, mapImage } from '@sitecore/common';
import { ImageRendering } from '@sitecore/types/manual/Image';
import { Image as SparkyImage, Stack, Text } from '@sparky';

const Image: FC<ImageRendering> = ({ fields, params }) => {
  const { src, sources, alt, aspectRatio, editable } = mapImage(fields?.image);
  if (!src) {
    if (editable) {
      return (
        <Stack gap="6">
          <img src="/temp/iconcache/sasdaf.png" alt="test" />
          {wrap(fields?.image)}
          <Text size={{ md: 'BodyL' }} color="textPrimary">
            {wrap(fields.captionText)}
          </Text>
        </Stack>
      );
    }
    return null;
  }

  return (
    <Stack gap="6">
      <SparkyImage
        width="100%"
        maxHeight={params?.maxHeight ? `${params?.maxHeight}px` : undefined}
        objectFit={params?.objectFit ?? 'contain'}
        src={src}
        sources={sources}
        aspectRatio={aspectRatio}
        alt={alt}
      />
      {fields?.captionText?.value || fields?.captionText?.editable ? (
        <Text size={{ md: 'BodyL' }} color="textPrimary">
          {wrap(fields.captionText)}
        </Text>
      ) : null}
    </Stack>
  );
};

export default Image;
